/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.info-table table {
  text-align: center;
  width: 100%;
  line-height: 1.5;
  border-collapse: collapse;
}
.info-table table thead tr {
  background: #f4f4f4;
  font-weight: bold;
}
.info-table table tbody tr td {
  color: #666;
}
.info-table table td {
  border: 1px solid #dfdfdf;
  padding: 14px 8px;
}
